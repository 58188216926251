<template>
  <div>
    <div v-if="!isLoading" class="box box-default">
      <div class="box-header">
        <div class="flex flex-col-sm gap-8 align-items-center justify-between">
          <h3 class="box-title">XChange Search</h3>
          <div class="flex flex-col-sm gap-8 align-items-center">
            <span class="text-bold text-danger no-wrap">{{ searching }}</span>
            <div class="flex align-items-center gap-8">
              <label class="flex align-items-center pointer no-wrap">
                <input type="checkbox" v-model="isInUse" value="1" /> Only In Use
              </label>
              <label class="flex align-items-center pointer no-wrap">
                <input type="checkbox" v-model="isActive" value="1" /> Only Active
              </label>
            </div>
            <v-select v-model="xChangeID" :clearable="true" :options="exchangeList" :reduce="r => r.ID"
              placeholder="All XChanges" label="Name" style="min-width: 230px;" taggable></v-select>
            <v-select v-model="altCoinID" :clearable="true" :options="formattedAltCoins" :reduce="a => a.ID"
              placeholder="All Coins" label="Name" style="min-width: 230px;" taggable></v-select>
            <input v-model="keyword" aria-label="search" class="form-control" placeholder="Search..." type="text"
              ref="searchInput" @keyup="search($event)" />
          </div>
        </div>
      </div>
      <div class="box-body no-padding">
        <div v-if="meta.total" class="footer">
          <pagination :meta="meta" @pageChange="updatePage"></pagination>
        </div>
        <div class="table-responsive">
          <table class="table table-striped table-condensed">
            <thead>
              <tr>
                <th>ID</th>
                <th>XChangeID</th>
                <th>Explorer</th>
                <th>XChangeName</th>
                <th>RegisteredEmail</th>
                <th>Coin Online</th>
                <th>CoinName</th>
                <th>IsPoolMaster</th>
                <th>IsInUse</th>
                <th>IsActive</th>
                <th>Descope</th>
                <th>FiatBridge</th>
                <th class="pointer" @click="sort('UpdatedOn')">
                  UpdatedOn
                  <span v-if="sortKey === 'UpdatedOn'" class="ml-4">
                    <i class="fa fa-sort"></i>
                  </span>
                </th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              <template v-if="records.length > 0">
                <tr v-for="(record, index) in records" :key="index">
                  <td>
                    <router-link tag="a"
                      :to="{ name: 'coin-health-detail', params: { id: record.AltCoinID }, query: { q: 'xChange', id: record.ID } }">
                      {{ record.ID }}
                    </router-link>
                  </td>
                  <td>
                    <router-link v-if="record.XChangeID" tag="a"
                      :to="{ name: 'coin-health-detail', params: { id: record.AltCoinID }, query: { q: 'xChange', id: record.XChangeID } }">
                      {{ record.XChangeID }}
                    </router-link>
                    <span v-else>{{ record.XChangeID }}</span>
                  </td>
                  <td>
                    <a target="_blank" :href="`${record.BlockExplorer}${record.Address}`" class="ml-8"
                      v-if="record.BlockExplorer && record.Address">
                      <i class="fa fa-globe" />
                    </a>
                  </td>
                  <td>{{ record.XChangeName }}</td>
                  <td>{{ record.RegisteredEmail }}</td>
                  <td>
                    <span v-html="formatBoolean(record.Online)"></span>
                  </td>
                  <td>
                    <router-link :to="{ name: 'coin-health-detail', params: { id: record.AltCoinID } }" tag="a">
                      {{ record.Name }}
                    </router-link>
                  </td>
                  <td>
                    <span v-html="formatBoolean(record.IsPoolMaster)"
                      @click="updateXChange(record, 'IsPoolMaster', 'IsPoolMaster', 'select', 'AltCoin_XChange')"></span>
                  </td>
                  <td>
                    <span v-html="formatBoolean(record.IsInUse)"
                      @click="updateXChange(record, 'IsInUse', 'IsInUse', 'select', 'AltCoin_XChange_AddressPool')"></span>
                  </td>
                  <td>
                    <span v-html="formatBoolean(record.IsActive)"
                      @click="updateXChange(record, 'IsActive', 'IsActive', 'select', 'AltCoin_XChange')"></span>
                  </td>
                  <td>
                    <span v-html="formatBoolean(record.Descope, 'descope')"
                      @click="updateXChange(record, 'Descope', 'Descope', 'select', 'AltCoin_XChange')"></span>
                  </td>
                  <td>
                    <span v-html="formatBoolean(record.FiatBridge)"
                      @click="updateXChange(record, 'FiatBridge', 'FiatBridge', 'select', 'AltCoin_XChange')"></span>
                  </td>
                  <td>{{ formatDate(record.UpdatedOn) }}</td>
                  <td>
                    <div class="d-flex">
                      <button class="btn btn-primary btn-sm mr-8" @click="clone(record.ID)">Clone</button>
                      <button class="btn btn-danger btn-sm" type="button"
                        @click="deleteXChangeItem(record.ID)">Delete</button>
                    </div>
                  </td>
                </tr>
              </template>
              <template v-else>
                <tr>
                  <td colspan="13">No record found!</td>
                </tr>
              </template>
            </tbody>
          </table>
          <div v-if="meta.total" class="footer">
            <pagination :meta="meta" @pageChange="updatePage"></pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { EXCHANGE_LIST, START_LOADING, STOP_LOADING } from "@/store/keys";
import Pagination from "@/components/Pagination.vue";
import { logout, saveNotification, formatDate } from "@/lib/utils";

export default {
  name: "XChangeSearch",
  components: { Pagination },
  data() {
    return {
      records: [],
      meta: {
        total: 0,
        page: 1,
        offset: 0,
        limit: 50,
      },
      xChangeID: "",
      altCoinID: "",
      keyword: "",
      isInUse: false,
      isActive: false,
      searching: "",
      sortKey: "UpdatedOn",
      order: "desc",
    };
  },
  computed: {
    ...mapGetters([
      "isLoading",
      "jwtToken",
      "exchangeList",
      "currentUser",
      "altCoins",
    ]),
    formattedAltCoins() {
      return this.altCoins.map(a => {
        return {
          ...a,
          Name: `${a.Name} (${a.LongName})`,
        };
      });
    },
  },
  watch: {
    altCoinID(val) {
      this.getRecords(1, false);
    },
    xChangeID(val) {
      this.getRecords(1, false);
    },
    isInUse(val) {
      this.getRecords(1, false);
    },
    isActive(val) {
      this.getRecords(1, false);
    },
  },
  methods: {
    showLoading() {
      this.$swal.fire({
        title: "",
        text: "Please wait...",
        showConfirmButton: false,
        backdrop: true,
      });
    },
    stopLoading() {
      this.$swal.close();
    },
    sort(key) {
      if (key !== this.sortKey) {
        this.order = "desc";
      } else {
        this.order = this.order === "asc" ? "desc" : "asc";
      }
      this.sortKey = key;
      this.getRecords(this.meta.page);
    },
    search(e) {
      if (e.key !== "/" && e.key !== "Control") {
        this.searching = "Searching...";
        if (this.timer) {
          clearTimeout(this.timer);
        }
        this.timer = setTimeout(() => {
          this.getRecords(1, false);
        }, 500);
      }
    },
    getRecords(page = 1, isLoader = true) {
      if (isLoader) {
        this.$store.commit(START_LOADING);
      }
      const apiBaseUrl = process.env.VUE_APP_ADMIN_API_URL;
      this.$http.get(`${apiBaseUrl}/?Call=ListXChangeCurrencies`, {
        headers: {
          Authorization: "Bearer " + this.jwtToken,
        },
        params: {
          id: this.xChangeID,
          altCoinID: this.altCoinID,
          keyword: this.keyword,
          isInUse: this.isInUse ? 1 : "",
          isActive: this.isActive ? 1 : "",
          page: page,
          limit: this.meta.limit,
          order: this.sortKey,
          orderBy: this.order,
        },
      }).then((response) => {
        this.$store.commit(STOP_LOADING);
        this.searching = "";
        if (response.status === 200) {
          this.records = response.data.data.records;
          this.meta = response.data.data.meta;
          if (this.keyword) {
            this.$nextTick(() => this.$refs.searchInput.focus());
          }
        }
      }).catch(error => {
        this.$store.commit(STOP_LOADING);
        this.searching = "";
        this.$toast.fire("", error.data.message, "error");
        saveNotification(error.data.message);
      });
    },
    updatePage(page) {
      this.getRecords(page);
    },
    formatBoolean(val, key = "") {
      const label = val ? "Yes" : "No";
      let lblClass = val ? "label-success" : "label-danger";
      if (key === "descope") {
        lblClass = !val ? "label-success" : "label-danger";
      }
      return `<label class="pointer label ${lblClass}">${label}</label>`;
    },
    getOptions(val) {
      let options = [];
      switch (val) {
        case "IsPoolMaster":
        case "IsInUse":
        case "IsActive":
        case "Descope":
        case "FiatBridge":
          options = [
            {
              id: 0,
              label: "No",
            },
            {
              id: 1,
              label: "Yes",
            },
          ];
          break;
      }
      return options;
    },
    updateXChange(record, key, label, type, table) {
      const val = record[key];
      const payload = JSON.parse(JSON.stringify({
        table: table,
        MerchantID: this.currentUser.ID,
        APIKey: this.currentUser.APIKey,
        ID: key === "IsInUse" ? record.XChangeID : record.ID,
        field: key === "IsActive" ? "isActive" : key,
        value: val ? 0 : 1,
        output: "json",
      }));
      const url = process.env.VUE_APP_API_URL;
      this.$http.post(`${url}/v2REAPI?Call=UpdateTable`, this.qs.stringify(payload), {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        withCredentials: true,
      }).then(response => {
        if (response.data && response.data.status_code === 400) {
          if (response.data.message.toLowerCase() === "no permission") {
            logout();
          }
        } else {
          this.$toast.fire("", response.data.message, "success");
          saveNotification(response.data.message);
          this.getRecords(this.meta.page, false);
        }
      }).catch(error => {
        console.log(error);
      });
    },
    deleteXChangeItem(id) {
      this.$swal.fire({
        text: "Are you sure want to delete XChange?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#00a65a",
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        focusCancel: true,
      }).then(({ value }) => {
        if (value && value === true) {
          const apiBaseUrl = process.env.VUE_APP_API_URL;
          this.$http.get(`${apiBaseUrl}/v2REAPI?Call=DeleteXChangeID`, {
            params: {
              ID: id,
              MerchantID: this.currentUser.ID,
              APIKey: this.currentUser.APIKey,
              output: "json",
            },
          }).then(response => {
            if (response.data.status_code === 200) {
              if (response.data.result.toLowerCase() === "success") {
                this.$toast.fire("", response.data.message, "success");
                this.getRecords(this.meta.page, false);
              }
            } else if (response.data.status_code === 400) {
              if (response.data.message.toLowerCase() === "no permission") {
                logout();
              }
            }
          }).catch(error => {
            console.log(error);
          });
        }
      });
    },
    clone(id) {
      this.$swal.fire({
        text: "Are you sure want to clone this XChange?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#00a65a",
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        focusCancel: true,
      }).then(({ value }) => {
        if (value && value === true) {
          const apiBaseUrl = process.env.VUE_APP_API_URL;
          this.$http.get(`${apiBaseUrl}/v2REAPI?Call=CloneXChangeID`, {
            params: {
              ID: id,
              MerchantID: this.currentUser.ID,
              APIKey: this.currentUser.APIKey,
              output: "json",
            },
          }).then(response => {
            if (response.data.status_code === 200) {
              if (response.data.result.toLowerCase() === "success") {
                this.$toast.fire("", response.data.message, "success");
                this.getRecords(this.meta.page, false);
              }
            } else if (response.data.status_code === 400) {
              if (response.data.message.toLowerCase() === "no permission") {
                logout();
              }
            }
          }).catch(error => {
            console.log(error);
          });
        }
      });
    },
    formatDate(val) {
      return formatDate(val);
    },
  },
  mounted() {
    this.$store.dispatch(EXCHANGE_LIST);
    if (this.$route.query.id) {
      this.xChangeID = this.$route.query.id;
      this.$router.replace({
        ...this.$router.currentRoute,
        query: {},
      });
    }
    this.getRecords();
  },
  beforeDestroy() {
    this.$store.commit(STOP_LOADING);
  },
};
</script>

<style scoped></style>
